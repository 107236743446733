/*
 * @Author: your name
 * @Date: 2022-02-14 11:55:31
 * @LastEditTime: 2022-03-09 15:51:09
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /yyac-system/src/store/mutations.js
 */
const mutations = {
    setNavBarIndex: (state, log) => {
        state.navBarIndex = log;
      }
}
export default mutations;