/*
 * @Author: your name
 * @Date: 2021-12-17 10:35:07
 * @LastEditTime: 2021-12-17 13:19:09
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /chinaoffer-phone/src/router/index.js
 */
import Vue from 'vue'
import Router from 'vue-router'

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(Router)
import Layout from "@/views/Layout"
import Homepage from "./Homepage"//首页
import Allusedcars from "./Allusedcars"//全部二手车
import cardetails from "./Cardetails"//全部二手车

const router = [
  {
    path: '/',
    name: "Layout",
    title: "外框",
    component: Layout,
    meta: { title: '首页' },
    redirect: {path: '/Homepage'},
    children: [
      ...Homepage,
      ...Allusedcars,
      ...cardetails
    ]
  },
  {
    path: "/login",
    name: "Login",
    title: "登录",
    component: resolve => require(['@/views/Login'], resolve)
}
]
export default new Router({
  // mode: 'history',
  routes: router
})
