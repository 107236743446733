<template>
    <div class="lt">

        <div class="home-content">
            <div class="home-top">
                <div class="menuList">
                    <span class="item1">云云问车</span>
                    <span class="city">宁波</span>
                </div>
                <div class="menuList">
                    <div :class="getNavBarIndex === index ? 'item itemActive': 'item'" v-for="(item,index) in menus" :key="index" @click="changeItem(index)">
                        <router-link :to="item.path">{{item.label}}</router-link>
                    </div>
                </div>
                <div class="menuList">
                    <el-button class="item" type="text">退出</el-button>
                    <el-button class="item" type="text">登陆</el-button>
                </div>
            </div>
        </div>
        <div>
            <router-view></router-view>
        </div>
        <div class="footer">
            <div class="footer_left">
                <div class="footer_left_top">
                    <img src="../../assets/PCslices/yywczt2.png" />
                </div>
                <div class="footer_left_button">
                    <a class="citys" href="https://beian.miit.gov.cn/" target="_blank">备案号:浙ICP备2021036624号-1</a>
                </div>
            </div>
            <div class="city">
                <!-- <span>打开微信扫一扫二维码</span>
                <span>在小程序中查看更多车辆</span> -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "App",
    data() {
        return {
            menus: [
                {
                    label: "首页",
                    path: "/Homepage",
                },
                {
                    label: "全部二手车",
                    path: "/Allusedcars",
                },
            ],
        };
    },
    methods: {
        Mainpage() {
            this.setNavBarIndex(-1);
            this.$router.push("Homepage");
        },
        changeItem(n) {
            this.setNavBarIndex(n);
        },
    },
};
</script>

<style lang="less" scoped>
.lt {
    width: 100%;
    height: 100%;
    min-width: 1440px;
    max-width: 1440px;
    margin: 0 auto;
}
.home-content {
    background-color: #066cf0;
    z-index: -1000;
    // background-image: linear-gradient(#0f24dff5, #0275f8);
    // background-image: url("../../assets/shouye.png");
    width: 100%;
    margin: 0 auto;
    .home-top {
        // padding: 0px 200px;
        width: 80%;
        margin: 0 auto;
        display: flex;
        height: 70px;
        .menuList {
            width: 33%;
        }
    }
}
.item1 {
    height: 70px;
    line-height: 70px;
    font-style: italic;
    font-size: 26px;
    font-weight: 600;
    color: #ffffff;
}
.menuList {
    display: flex;
    justify-content: flex-start;
    height: 100%;
}
.city {
    height: 70px;
    line-height: 70px;
    font-size: 12px;
    color: #ffffff;
    margin: 0px 20px;
}
.citys {
    height: 30px;
    line-height: 30px;
    font-size: 15px;
    color: #ffffff;
    margin: 0px 20px;
}
.item {
    height: 100%;
    color: rgb(255, 255, 255);
    line-height: 70px;
    padding: 0px 24px;
    position: relative;
    border-bottom: transparent;
    transition: 0.4s;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    a {
        color: #ffffff;
        text-decoration: none;
    }
    &:hover {
        a {
            color: #5496ec;
        }
    }
}
.itemActive {
    // background: linear-gradient(180deg, #5297ef 0%, #1457b2 100%);
    a {
        color: #ffffff;
    }
}
.footer {
    height: 248px;
    line-height: 248px;
    padding: 50px 100px;
    background-color: rgb(1, 15, 38);
    display: flex;
    .footer_left {
        width: 50%;
        .footer_left_top {
            height: 100px;
            line-height: 100px;
            border-bottom: 1px solid #ffffff;
        }
        .footer_left_button {
            height: 100px;
            line-height: 100px;
        }
    }
}
</style>
