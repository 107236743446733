/*
 * @Author: your name
 * @Date: 2022-02-14 11:56:12
 * @LastEditTime: 2022-03-09 15:52:11
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /yyac-system/src/store/getters.js
 */
const getters = {
    getNavBarIndex(state) {
        return state.navBarIndex;
    }
   
}
export default getters