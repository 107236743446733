export default [
    {
        path: '/cardetails',
        name: "cardetails",
        title: "二手车详情",
        component: resolve => require(['@/views/cardetails'], resolve),
    },
    {
        path: '/cardetails/configuration',
        name: "configuration",
        title: "查看参数配置",
        component: resolve => require(['@/views/cardetails/configuration'], resolve),
    },
]