<template>
  <div v-if="isChecked" class="button-container">
      <slot></slot>
  </div>
  <div v-else class="hover">
      <slot></slot>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less">
.button-container {
    width: 41px;
    height: 22px;
}
.hover {
    width: 41px;
    height: 22px;
    background: #0179FF;
    color: #FFF;
}
</style>