/*
 * @Author: your name
 * @Date: 2022-03-09 15:53:55
 * @LastEditTime: 2022-03-09 15:55:00
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /yywc-evaluation/src/mixins/index.js
 */
import { mapGetters, mapActions } from "vuex";
export default {
    install(Vue) {
        Vue.mixin({
            computed: {
              ...mapGetters(['getNavBarIndex'])
            },
            methods: {
              ...mapActions(['setNavBarIndex'])
            },
            filters: {
              
            }
          })
    }
}