import Vue from 'vue'
import App from './App.vue'
import router from "@/permission";
import ElementUI from 'element-ui';
import Antd from 'ant-design-vue';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI, {size: 'small'});
import '@/styles/index.less';
import store from "@/store";
import mixins from "@/mixins";
import selfUi from "@/components/index.js";
Vue.use(selfUi);
Vue.use(Antd);
Vue.use(mixins);
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
